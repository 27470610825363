
import { useContext, useEffect, useState } from "react";
import { DatosDetailsStepsThreeCard, DatosDetailsStepsThreeCardDefault } from "./interfaces";
import { StepFourContext, StepsContext } from "../../providers";
import GeneralHelper from "../../../../../helpers/GeneralHelper";
import { appConfigKey, appDefaultValues } from "../../../../../../config/Config";
import { AgregarDivisa } from "../../../../../helpers/DivisaHelper";

const IdentificadorProductoCompraMaestra = 122;

const DetailsStepsThreeCard = () => {

  const {
    viewModelStepOneFinal,
    viewModelStepTwoFinal,
    viewModelStepThreeFinal,
    TCEA,
    solicitudDataActualizado,
    readonlyForm,
    solicitudData,
    stepsTotalFinanciamiento,
    isTitularJuridico,
  } = useContext(StepsContext);

  const [viewModel, setViewModel] = useState<DatosDetailsStepsThreeCard>(DatosDetailsStepsThreeCardDefault);

  const divisa = viewModelStepTwoFinal.MonedaId;
  const capitalSimulacionString: string = GeneralHelper.AgregarComasAMiles(stepsTotalFinanciamiento.toFixed(2));
  let tempTotalFinanciamiento = AgregarDivisa(capitalSimulacionString, Number(divisa));

  let cuotaFinal: any = "-";

  if (viewModelStepThreeFinal.cronograma && viewModelStepThreeFinal.formulario) {

    if (viewModelStepThreeFinal.formulario.identProducto === IdentificadorProductoCompraMaestra) {
      const listaCronograma = viewModelStepThreeFinal.cronograma.listaConograma;
      if (listaCronograma?.length !== undefined) {
        const cantidadRegistros = listaCronograma.length;
        cuotaFinal = listaCronograma[cantidadRegistros - 1].pagoTotal;
      }
    }

  }

  let gps: any = "-";

  if (readonlyForm === false) {
    if (solicitudDataActualizado.content) {
      gps = solicitudDataActualizado.content.descC_PROVEEDOR_GPS;
    }
  } else {
    gps = solicitudData.descC_PROVEEDOR_GPS;
  }

  const listaSeguroDesgravamen = viewModelStepThreeFinal.listas.tempListaTipoCoberturaDesg;
  const coberturaDesg = listaSeguroDesgravamen.find((x: any) => x.id === viewModelStepThreeFinal.formulario.identTipoCoberturaDesg);

  let score: any = appDefaultValues.StringEmpty;

  if (readonlyForm === false) {
    score = viewModelStepThreeFinal.formulario.score;
  } else {
    score = solicitudData.score;
  }

  let tempTCEA: any = appDefaultValues.StringEmpty;

  if (readonlyForm === false) {
    tempTCEA = `${TCEA}%`;
  } else {
    tempTCEA = `${solicitudData.tcea}%`;
  }

  useEffect(() => {
    if (viewModelStepThreeFinal !== null) {

      let producto = viewModelStepThreeFinal.formulario.Producto;

      if (readonlyForm) {
        producto = solicitudData.descC_PRODUCTO;
      }

      setViewModel({
        ...viewModel,
        Producto: producto,
        CostoSegVeh: viewModelStepThreeFinal.formulario.costoSeguroVehicular + ' %',//"2.4%",
        TasaSegDesg: readonlyForm ? solicitudData.descC_COBERTURA_SEGURO_DESGRAVAMENT : coberturaDesg?.description,
        VisibleTasaSegDesg: viewModelStepOneFinal.newTitularData.TipoDocumentoId != appConfigKey.keyIdRUC,
        TotalFinanciamiento: tempTotalFinanciamiento,
        SegVehicular: viewModelStepThreeFinal.formulario.SeguroVehicular == undefined ? solicitudData.descC_SEGURO_VEHICULAR : viewModelStepThreeFinal.formulario.SeguroVehicular,
        TEA: viewModelStepThreeFinal.formulario.tea + ' %',//"10.30%",
        CuotasDobles: viewModelStepThreeFinal.formulario.esCuotasDobles ? 'Si' : 'No',
        TotalSegVehicular: appDefaultValues.StringEmpty,
        Score: viewModelStepThreeFinal.formulario.score,//"546",
        TCEA: tempTCEA,
        GPS: gps,
        TotalGastos: "",//"S/. 1,345.00",
        CuotaFinal: `${viewModelStepTwoFinal.MonedaId === appConfigKey.keyIdentificadorDolares ? appConfigKey.keySimboloDolares1 : appConfigKey.keySimboloSoles} ${cuotaFinal}`,//"S/. 1,094.00",
        Plazo: viewModelStepThreeFinal.formulario.plazosMeses == undefined ? solicitudData.indeT_PLAZO : viewModelStepThreeFinal.formulario.plazosMeses,
      });
    }
  }, [viewModelStepThreeFinal]);

  return (
    <>
      <div className="border rounded-10 p-3 p-md-2 mb-4 mb-md-4 card body-details">
        <div className="row g-2 mb-2 align-items-center item-body-details">


          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Producto</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.Producto}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Seguro Vehicular</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.SegVehicular}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Score</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{!isTitularJuridico ? viewModel.Score : '-'}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Cuota final</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.CuotaFinal}</span>
                </div>
              </div>
            </div>
          </div>


          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Tasa seguro veh.</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.CostoSegVeh}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>TEA</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.TEA}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>TCEA</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.TCEA}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Plazo</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.Plazo}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-12">
            {viewModel.VisibleTasaSegDesg &&
              (
                <div className="row g-2 mb-2">
                  <div className="col-lg-6 col-md-6">
                    <span>Cobertura Desg.</span>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="text-long">
                      <span className="text-negrita">{viewModel.TasaSegDesg}</span>
                    </div>
                  </div>
                </div>
              )
            }
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Cuotas dobles</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.CuotasDobles}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>GPS</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.GPS}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span></span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">&nbsp;</span>
                </div>
              </div>
            </div>
          </div>


          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Total Financiamiento</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.TotalFinanciamiento}</span>

                </div>
              </div>
            </div>
            {/* <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Total Seg. Vehicular</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.TotalSegVehicular}</span>
                </div>
              </div>
            </div> */}
            {/* <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Total Gastos</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.TotalGastos}</span>
                </div>
              </div>
            </div> */}
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span></span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">&nbsp;</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  );
};

export default DetailsStepsThreeCard;


