import { appConfigKey, appSecurityConfiguration } from "../../../../../config/Config";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import TimeHelper from "../../../../helpers/TimeHelper";
import { General } from "../../3.10.-base/obtenerServicios";
import { FiadorInterface, FiadorPersonaInterface } from "../../3.10.-base/interfaces/fiadorInterface";
import { DatosPersonalesDelConyuge, DatosPersonalesDelConyugeDefault } from "../components/DatosPersonalesDelConyugeCard/interfaces";
import { DatosPersonalesDelTitular, DatosPersonalesDelTitularDefault } from "../components/DatosPersonalesDelTitularCard/interfaces";
import { LaboresAdicionalesTitular, LaboresAdicionalesTitularDefault } from "../components/DatosLaboralesTitularCard/interfaces";
import { LaboresAdicionalesConyuge, LaboresAdicionalesConyugeDefault } from "../components/DatosLaboralesDelConyuge/interfaces";
import { DatosDetailsStepsOneCard, DatosDetailsStepsOneCardDefault } from "../../3.10.-base/components/DetailsStepsOneCard/interfaces";
import StepOneHelper from "./StepOneHelper";
import { GenerarMnFormatoRegimenRequest } from "../../../../models/RequestManager/solicitud-formato-regimen-request.model";
import { ConvertidorTablaHelper } from "../../../1.-shared/1.6.-table/1.6.1.-regimen/helpers/ConvertidorTablaHelper";

export const ConvertidorHelper = {
    ObtenerEstadosFinancieros: (responseEstadosFinancieros: any[]) => {
        const tempEstadosFinancieros: any = [];

        responseEstadosFinancieros.forEach(item => {
            tempEstadosFinancieros.push({
                RegistroId: item.idenT_REGISTRO,
                Descripcion: item.descC_REGISTRO,
                FechaAnual: item.fecH_ANUAL,
                FechaEEFF: item.fecH_EEFF_SITUACIONAL,
                MontoAnual: item.monT_ANUAL,
                MontoEEFF: item.monT_EEFF_SITUACIONAL
            });
        });

        return tempEstadosFinancieros;
    },
    ObtenerDatosPersonaCompraParaConyuge: (tempPersona: any) => {
        const tempData: any = {};

        tempData.TipoDocumentoId = tempPersona.iD_TIPO_DOCUMENTO;
        tempData.NumeroDocumento = tempPersona.codL_NUMERO_DOCUMENTO;
        tempData.Nombres = tempPersona.descL_NOMBRES;
        tempData.ApellidoPaterno = tempPersona.descL_APELLIDO_PATERNO;
        tempData.ApellidoMaterno = tempPersona.descL_APELLIDO_MATERNO;
        tempData.FechaNacimiento = TimeHelper.ObtenerFormatoFechaAmericana(tempPersona.fecH_NACIMIENTO_CONSTITUCION);
        tempData.GeneroId = tempPersona.iD_GENERO;
        tempData.MagnitudId = tempPersona.iD_MAGNITUD;
        tempData.ActividadEconomicaId = tempPersona.iD_ACTIVIDAD_ECONOMICA;

        return tempData;
    },
    ObtenerDatosPersonaCompraPara: (tempPersona: any) => {
        const tempData: any = {};

        tempData.TipoDocumentoId = tempPersona.iD_TIPO_DOCUMENTO;

        if (Number(tempPersona.iD_TIPO_DOCUMENTO) === appConfigKey.keyIdRUC) {
            tempData.RazonSocial = tempPersona.descL_NOMBRE_COMPLETO;
        } else {
            tempData.Nombres = tempPersona.descL_NOMBRES;
            tempData.ApellidoPaterno = tempPersona.descL_APELLIDO_PATERNO;
            tempData.ApellidoMaterno = tempPersona.descL_APELLIDO_MATERNO;
        }

        tempData.NumeroDocumento = tempPersona.codL_NUMERO_DOCUMENTO;
        tempData.FechaNacimiento = TimeHelper.ObtenerFormatoFechaAmericana(tempPersona.fecH_NACIMIENTO_CONSTITUCION);
        tempData.GeneroId = tempPersona.iD_GENERO;
        tempData.EstadoCivilId = `${tempPersona.iD_ESTADO_CIVIL}`;
        tempData.NacionalidadId = tempPersona.iD_NACIONALIDAD;
        tempData.PaisRecidenciaId = tempPersona.iD_NACIONALIDAD;

        return tempData;
    },
    ObtenerDatosConyuge: (tempConyuge: any): DatosPersonalesDelConyuge => {
        const tempTitularConyugue: DatosPersonalesDelConyuge = DatosPersonalesDelConyugeDefault;
        tempTitularConyugue.TipoDocumentoId = tempConyuge.iD_TIPO_DOCUMENTO;
        tempTitularConyugue.NumeroDocumento = tempConyuge.codL_NUMERO_DOCUMENTO;
        tempTitularConyugue.Nombres = tempConyuge.descL_NOMBRES;
        tempTitularConyugue.ApellidoPaterno = tempConyuge.descL_APELLIDO_PATERNO;
        tempTitularConyugue.ApellidoMaterno = tempConyuge.descL_APELLIDO_MATERNO;

        if (tempConyuge.fecH_NACIMIENTO_CONSTITUCION !== null) {
            tempTitularConyugue.FechaNacimiento = TimeHelper.ObtenerFormatoFechaAmericana(tempConyuge.fecH_NACIMIENTO_CONSTITUCION);
        } else {
            tempTitularConyugue.FechaNacimiento = null;
        }

        tempTitularConyugue.GeneroId = tempConyuge.iD_GENERO;
        tempTitularConyugue.MagnitudId = tempConyuge.iD_MAGNITUD;
        tempTitularConyugue.ActividadEconomicaId = tempConyuge.iD_ACTIVIDAD_ECONOMICA;
        tempTitularConyugue.NacionalidadId = tempConyuge.iD_NACIONALIDAD;
        tempTitularConyugue.NivelEstudioId = tempConyuge.iD_NIVEL_ESTUDIOS;
        tempTitularConyugue.Correo = tempConyuge.descL_EMAIL;
        tempTitularConyugue.ProfesionId = tempConyuge.iD_PROFESION;
        tempTitularConyugue.CargoLaboralId = tempConyuge.idenT_CARGO_LABORAL;
        tempTitularConyugue.CargoLaboral = tempConyuge.descL_DESCRIPCION_CARGO;
        return tempTitularConyugue;
    },
    ObtenerFiadoresPersonas: async (tempPersonas: any, tempFiadores: Array<FiadorInterface>) => {

        const response: Array<FiadorPersonaInterface> = [];

        for (let index = 0; index < tempPersonas.length; index++) {

            const tempPersona = tempPersonas[index];

            const fiadorPersona: FiadorPersonaInterface = {
                PersonaId: Number(tempPersona.idenT_PERSONA),
                TipoDocumentoId: Number(tempPersona.iD_TIPO_DOCUMENTO),
                NumeroDocumento: String(tempPersona.codL_NUMERO_DOCUMENTO),
                Nombres: String(tempPersona.descL_NOMBRES),
                ApellidoPaterno: String(tempPersona.descL_APELLIDO_PATERNO),
                ApellidoMaterno: String(tempPersona.descL_APELLIDO_MATERNO),
                FechaNacimiento: tempPersona.fecH_NACIMIENTO_CONSTITUCION,
                GeneroId: String(tempPersona.iD_GENERO),
                NacionalidadId: Number(tempPersona.iD_NACIONALIDAD),
                PaisRecidenciaId: Number(tempPersona.iD_NACIONALIDAD),
                EstadoCivilId: tempPersona.iD_ESTADO_CIVIL,
                ProfesionId: Number(tempPersona.iD_PROFESION),
                Conyuge: {
                    PersonaId: 0,
                    ApellidoMaterno: '',
                    ApellidoPaterno: '',
                    GeneroId: '',
                    NacionalidadId: 0,
                    Nombres: '',
                    NumeroDocumento: '',
                    PaisRecidenciaId: 0,
                    ProfesionId: 0,
                    TipoDocumentoId: 0,
                    FechaNacimiento: ''
                }
            };

            if (Number(tempPersona.iD_ESTADO_CIVIL) === appConfigKey.keyCodigoEstadoCivilCasado) {

                const tempFiador = tempFiadores.find((x: FiadorInterface) => x.PersonaId === tempPersona.idenT_PERSONA);

                if (tempFiador !== undefined) {

                    const tempConyuguePersona: any = await General.Persona(tempFiador.ConyugeId);

                    if (tempConyuguePersona !== undefined) {
                        fiadorPersona.Conyuge = {
                            PersonaId: Number(tempConyuguePersona.idenT_PERSONA),
                            TipoDocumentoId: Number(tempConyuguePersona.iD_TIPO_DOCUMENTO),
                            NumeroDocumento: String(tempConyuguePersona.codL_NUMERO_DOCUMENTO),
                            Nombres: String(tempConyuguePersona.descL_NOMBRES),
                            ApellidoPaterno: String(tempConyuguePersona.descL_APELLIDO_PATERNO),
                            ApellidoMaterno: String(tempConyuguePersona.descL_APELLIDO_MATERNO),
                            FechaNacimiento: tempConyuguePersona.fecH_NACIMIENTO_CONSTITUCION,
                            GeneroId: String(tempConyuguePersona.iD_GENERO),
                            ProfesionId: Number(tempConyuguePersona.iD_PROFESION),
                            NacionalidadId: Number(tempConyuguePersona.iD_NACIONALIDAD),
                            PaisRecidenciaId: Number(tempConyuguePersona.iD_PAIS_RESIDENCIA),
                        };
                    }

                }

            }

            response.push(fiadorPersona);
        }

        return response;
    },
    ObtenerPersonasPorFiadores: async (tempFiadores: Array<FiadorInterface>) => {

        const tempPersonaPorFiador = [];

        for (let index = 0; index < tempFiadores.length; index++) {
            const element = tempFiadores[index];
            tempPersonaPorFiador.push(await General.Persona(element.PersonaId));
        }

        return tempPersonaPorFiador;

    },
    ObtenerIngresoPrincipal: async (ingresoPrincipal: any, tempDatosLaboralesTitular: any) => {

        let tempDatosEmpresaPrincipal: any = null;
        if (GeneralHelper.EsObjetoNullOrUndefined(ingresoPrincipal)) {
            return {
                tempDatosLaboralesTitular,
                tempDatosEmpresaPrincipal
            };
        }

        if (GeneralHelper.ParseNumber(ingresoPrincipal?.idenT_EMPRESA) > 0) {
            tempDatosEmpresaPrincipal = await General.Empresa(ingresoPrincipal.idenT_EMPRESA);
            const razonSocialEmpresaPrincipal = tempDatosEmpresaPrincipal.descL_RAZON_SOCIAL_EMPRESA;
            tempDatosLaboralesTitular.ruc = tempDatosEmpresaPrincipal.descL_NRO_DOC;
            tempDatosLaboralesTitular.razonSocial = razonSocialEmpresaPrincipal;
        }

        tempDatosLaboralesTitular.identTipoMoneda = ingresoPrincipal.iD_TIPO_MONEDA_INGRESO;
        tempDatosLaboralesTitular.ingresosNetos = GeneralHelper.AgregarComasAMiles(String(ingresoPrincipal.monT_INGRESO_NETO));
        tempDatosLaboralesTitular.fechaIngresoLaboral = ingresoPrincipal.fecH_INGRESO_EMPRESA != null ? TimeHelper.ObtenerFormatoFechaAmericana(ingresoPrincipal.fecH_INGRESO_EMPRESA) : ingresoPrincipal.fecH_INGRESO_EMPRESA;
        tempDatosLaboralesTitular.ActividadEconomicaId = ingresoPrincipal.iD_ACTIVIDAD_ECONOMICA;
        tempDatosLaboralesTitular.identCategoriaLaboral = ingresoPrincipal.iD_CATEGORIA_LABORAL;
        tempDatosLaboralesTitular.regimenId = ingresoPrincipal.iD_REGIMEN;
        tempDatosLaboralesTitular.anualizado = ingresoPrincipal.inD_ANUALIZADO;

        const montoIngresoNeto = Number(ingresoPrincipal.monT_INGRESO_NETO);
        tempDatosLaboralesTitular.calculoAnualizado = GeneralHelper.CalcularAnulizado(montoIngresoNeto);

        return {
            tempDatosLaboralesTitular,
            tempDatosEmpresaPrincipal
        };
    },
    ObtenerIngresoAnterior: async (ingresoAnterior: any, tempDatosLaboralesTitular: any) => {
        const tempDatosEmpresaSecundaria: any = await General.Empresa(ingresoAnterior.idenT_EMPRESA);
        const razonSocialEmpresaSecundaria = tempDatosEmpresaSecundaria.descL_RAZON_SOCIAL_EMPRESA;

        tempDatosLaboralesTitular.identCategoriaLaboralAnterior = ingresoAnterior.iD_CATEGORIA_LABORAL;
        tempDatosLaboralesTitular.rucAnterior = tempDatosEmpresaSecundaria.descL_NRO_DOC;
        tempDatosLaboralesTitular.razonSocialAnterior = razonSocialEmpresaSecundaria;
        tempDatosLaboralesTitular.fechaIngresoLaboralAnterior = TimeHelper.ObtenerFormatoFechaAmericana(ingresoAnterior.fecH_INGRESO_EMPRESA);
        tempDatosLaboralesTitular.fechaHastaAnterior = TimeHelper.ObtenerFormatoFechaAmericana(ingresoAnterior.fecH_FIN_TRABAJO_ANTERIOR);
        tempDatosLaboralesTitular.regimenIdAnterior = ingresoAnterior.iD_REGIMEN;

        return {
            tempDatosLaboralesTitular,
            tempDatosEmpresaSecundaria
        };
    },
    ObtenerOtrosIngresos: async (ingresoOtros: any, tempDatosLaboralesTitular: any) => {
        const tempListOtrosIngresos: any = [];

        const responseOtrasEmpresas = [];

        for (let index = 0; index < ingresoOtros.length; index++) {
            const element = ingresoOtros[index];
            const tempDatosEmpresaOtro: any = await General.Empresa(element.idenT_EMPRESA);
            responseOtrasEmpresas.push(tempDatosEmpresaOtro);
            const razonSocialEmpresaOtro = tempDatosEmpresaOtro.descL_RAZON_SOCIAL_EMPRESA;
            const tempIngreso: any = {
                identCategoriaLaboral: element.iD_CATEGORIA_LABORAL,
                ruc: tempDatosEmpresaOtro.descL_NRO_DOC,
                razonSocial: razonSocialEmpresaOtro,
                identTipoMoneda: element.iD_TIPO_MONEDA_INGRESO,
                ingresosNetos: GeneralHelper.AgregarComasAMiles(String(element.monT_INGRESO_NETO)),
                fechaIngresoLaboral: TimeHelper.ObtenerFormatoFechaAmericana(element.fecH_INGRESO_EMPRESA),
                regimenId: element.iD_REGIMEN,
                actividadEconomicaId: element.iD_ACTIVIDAD_ECONOMICA,
                anualizado: element.inD_ANUALIZADO,
                calculoAnualizado: GeneralHelper.CalcularAnulizado(element.monT_INGRESO_NETO)
            };

            tempListOtrosIngresos.push(tempIngreso);
        }

        tempDatosLaboralesTitular.listaOtrosTrabajos = tempListOtrosIngresos;

        return {
            tempDatosLaboralesTitular,
            responseOtrasEmpresas
        };
    },
    ObtenerMatrizRegimen: async (request: GenerarMnFormatoRegimenRequest) => {
        const response = await General.GenerarMnFormatoByRegimen(request);
        if (!response) return null;

        return ConvertidorTablaHelper.ObtenerValoresPorPerfil(response);
    },
    ObtenerIngresos: async (idenT_PERSONA: number, idenT_SOLICITUD: number | null = null) => {
        let tempDatosLaboralesTitular: any = {};

        let responseEmpresaIngresoPrincipal = null;
        let responseEmpresaIngresoAnterior = null;
        let responseEmpresasOtrosIngresos = [];

        const ingresos = await General.Ingresos(idenT_PERSONA);

        const ingresoPrincipal = ingresos.find((ingreso: any) => ingreso.inD_INGRESO_PRINCIPAL === true);
        const ingresoAnterior = ingresos.find((ingreso: any) => ingreso.inD_INGRESO_PRINCIPAL === false && ingreso.inD_TRABAJO_ANTERIOR === true);
        const ingresoOtros = ingresos.filter((ingreso: any) => ingreso.inD_INGRESO_PRINCIPAL === false && ingreso.inD_TRABAJO_ANTERIOR === false);

        const responseObtenerIngresoPrincipal = await ConvertidorHelper.ObtenerIngresoPrincipal(ingresoPrincipal, tempDatosLaboralesTitular);

        tempDatosLaboralesTitular = responseObtenerIngresoPrincipal.tempDatosLaboralesTitular;
        responseEmpresaIngresoPrincipal = responseObtenerIngresoPrincipal.tempDatosEmpresaPrincipal;

        if (ingresoAnterior !== undefined) {
            tempDatosLaboralesTitular.tieneTrabajoAnterior = true;
            const responseObtenerIngresoAnterior = await ConvertidorHelper.ObtenerIngresoAnterior(ingresoAnterior, tempDatosLaboralesTitular);
            tempDatosLaboralesTitular = responseObtenerIngresoAnterior.tempDatosLaboralesTitular;
            responseEmpresaIngresoAnterior = responseObtenerIngresoAnterior.tempDatosEmpresaSecundaria;
        }


        const responseObtenerOtrosIngresos = await ConvertidorHelper.ObtenerOtrosIngresos(ingresoOtros, tempDatosLaboralesTitular);
        tempDatosLaboralesTitular = responseObtenerOtrosIngresos.tempDatosLaboralesTitular;
        responseEmpresasOtrosIngresos = responseObtenerOtrosIngresos.responseOtrasEmpresas;

        if (tempDatosLaboralesTitular.regimenId && idenT_SOLICITUD) {
            let formatoRequest = new GenerarMnFormatoRegimenRequest();
            formatoRequest.identRegimen = tempDatosLaboralesTitular.regimenId;
            formatoRequest.identSolicitud = idenT_SOLICITUD;
            formatoRequest.identUsuario = appSecurityConfiguration.SessionUserId;
            formatoRequest.identPerfil = appSecurityConfiguration.SessionPerfil;
            const matrizRegimen = await ConvertidorHelper.ObtenerMatrizRegimen(formatoRequest);
            tempDatosLaboralesTitular.matrizRegimen = matrizRegimen;
        }

        return {
            dataFormat: tempDatosLaboralesTitular,
            bodyResponse: {
                ingresos: ingresos,
                empresaIngresoPrincipal: responseEmpresaIngresoPrincipal,
                empresaIngresoAnterior: responseEmpresaIngresoAnterior,
                empresaOtrosIngresos: responseEmpresasOtrosIngresos
            }
        };
    },
    ObtenerDatosTitular: (tempTitular: any, tempSolicitud: any): DatosPersonalesDelTitular => {
        const tempDataTitular: DatosPersonalesDelTitular = DatosPersonalesDelTitularDefault;
        tempDataTitular.TipoDocumentoId = tempTitular.iD_TIPO_DOCUMENTO;
        tempDataTitular.NumeroDocumento = tempTitular.codL_NUMERO_DOCUMENTO;
        tempDataTitular.Nombres = tempTitular.descL_NOMBRES;
        tempDataTitular.ApellidoPaterno = tempTitular.descL_APELLIDO_PATERNO;
        tempDataTitular.ApellidoMaterno = tempTitular.descL_APELLIDO_MATERNO;
        tempDataTitular.EstadoCivilId = tempTitular.iD_ESTADO_CIVIL;
        tempDataTitular.GeneroId = tempTitular.iD_GENERO;
        tempDataTitular.Correo = tempTitular.descL_EMAIL;
        tempDataTitular.Domicilio = (!tempTitular.descC_DOMICILIO || tempTitular.descC_DOMICILIO === '') ? 0 : tempTitular.descC_DOMICILIO;
        tempDataTitular.Celular = tempTitular.descC_CELULAR;
        tempDataTitular.FechaSolicitud = TimeHelper.ObtenerFormatoFechaAmericana(tempSolicitud.fecH_CREACION);
        tempDataTitular.TipoPersonaId = tempTitular.iD_TIPO_PERSONA;
        tempDataTitular.FechaNacimiento = TimeHelper.ObtenerFormatoFechaAmericana(tempTitular.fecH_NACIMIENTO_CONSTITUCION);
        tempDataTitular.MagnitudId = tempTitular.iD_MAGNITUD;
        tempDataTitular.DocumentoComplementarioId = tempTitular.iD_TIPO_DOCUMENTO_COMPLEMENTARIO;
        tempDataTitular.NumeroDocumentoComplementario = GeneralHelper.ParseString(tempTitular.codL_NUMERO_DOCUMENTO_COMPLEMENTARIO);
        tempDataTitular.ActividadEconomicaId = tempTitular.iD_ACTIVIDAD_ECONOMICA;
        tempDataTitular.ClientePep = false;
        tempDataTitular.SeparacionBienes = tempTitular.inD_SEPARACION_BIENES;
        tempDataTitular.NacionalidadId = tempTitular.iD_NACIONALIDAD;
        tempDataTitular.NivelEstudiosId = tempTitular.iD_NIVEL_ESTUDIOS;
        tempDataTitular.ProfesionId = tempTitular.iD_PROFESION;
        tempDataTitular.NroHijos = tempTitular.monT_NUMERO_HIJOS;
        tempDataTitular.NroDependientes = tempTitular.monT_NUMERO_DEPENDIENTES;
        tempDataTitular.NombreCompletoContacto1 = tempTitular.descL_NOMBRE_COMPLETO_CONTACTO1;
        tempDataTitular.NombreCompletoContacto2 = tempTitular.descL_NOMBRE_COMPLETO_CONTACTO2;
        tempDataTitular.NumeroContacto1 = tempTitular.descC_NUMERO_CONTACTO1;
        tempDataTitular.NumeroContacto2 = tempTitular.descC_NUMERO_CONTACTO2;
        tempDataTitular.NombreVia = tempTitular.descM_NOMBRE_VIA;
        tempDataTitular.NumeroDomicilio = tempTitular.descC_NUMERO;
        tempDataTitular.IntDptoPiso = tempTitular.descC_INTERIOR_DEPARTAMENTO_PISO;
        tempDataTitular.Manzana = tempTitular.descC_MANZANA;
        tempDataTitular.Lote = tempTitular.descC_LOTE;
        tempDataTitular.NombreAgrupacion = tempTitular.descM_NOMBRE_AGRUPACION;
        tempDataTitular.Referencia = tempTitular.descL_REFERENCIA;
        tempDataTitular.TipoViviendaId = tempTitular.iD_TIPO_VIVIENDA;
        tempDataTitular.TiempoResidenciaId = tempTitular.iD_TIEMPO_RESIDENCIA;
        tempDataTitular.CargoLaboralId = tempTitular.idenT_CARGO_LABORAL;
        tempDataTitular.CargoLaboral = tempTitular.descL_DESCRIPCION_CARGO;
        tempDataTitular.RangoVentaAnualId = tempTitular.iD_RANGO_VENTA_ANUAL;
        tempDataTitular.RangoVentaAnual = tempTitular.descC_RANGO_VENTA_ANUAL;
        tempDataTitular.FechaInicioActividad = TimeHelper.ObtenerFormatoFechaAmericana(tempTitular.fecH_INICIO_ACTIVIDAD ?? '');
        tempDataTitular.GiroNegocioId = tempTitular.iD_GIRO_NEGOCIO;
        tempDataTitular.GiroNegocio = tempTitular.descC_GIRO_NEGOCIO;
        return tempDataTitular;
    },
    ObtenerDatosLaboresAdicionalesTitular: (tempLaboresAdicionalTitular: any): LaboresAdicionalesTitular => {
        const tempDataLaboresAdicionalesTitular: LaboresAdicionalesTitular = LaboresAdicionalesTitularDefault;
        tempDataLaboresAdicionalesTitular.ActividadEconomicaId = tempLaboresAdicionalTitular.iD_ACTIVIDAD_ECONOMICA;
        tempDataLaboresAdicionalesTitular.PaisId = tempLaboresAdicionalTitular.idenT_PAIS;
        tempDataLaboresAdicionalesTitular.NombreVia = tempLaboresAdicionalTitular.descM_NOMBRE_VIA;
        tempDataLaboresAdicionalesTitular.NumeroVia = tempLaboresAdicionalTitular.descC_NUMERO;
        tempDataLaboresAdicionalesTitular.IntDptoPiso = tempLaboresAdicionalTitular.descC_INTERIOR_DEPARTAMENTO_PISO;
        tempDataLaboresAdicionalesTitular.Manzana = tempLaboresAdicionalTitular.descC_MANZANA;
        tempDataLaboresAdicionalesTitular.Lote = tempLaboresAdicionalTitular.descC_LOTE;
        tempDataLaboresAdicionalesTitular.NombreAgrupacion = tempLaboresAdicionalTitular.descM_NOMBRE_AGRUPACION;
        tempDataLaboresAdicionalesTitular.Referencia = tempLaboresAdicionalTitular.descL_REFERENCIA;
        tempDataLaboresAdicionalesTitular.Telefono = GeneralHelper.ParseString(tempLaboresAdicionalTitular.descC_TELEFONO);
        tempDataLaboresAdicionalesTitular.Anexo = GeneralHelper.ParseString(tempLaboresAdicionalTitular.descC_ANEXOS);
        tempDataLaboresAdicionalesTitular.Correo = GeneralHelper.ParseString(tempLaboresAdicionalTitular.descC_CORREO);
        tempDataLaboresAdicionalesTitular.DistritoId = tempLaboresAdicionalTitular.idenT_UBIGEO;
        tempDataLaboresAdicionalesTitular.ProvinviaId = GeneralHelper.ObtenerProvinciaId(tempLaboresAdicionalTitular.idenT_UBIGEO);
        tempDataLaboresAdicionalesTitular.DepartamentoId = GeneralHelper.ObtenerDepartamentoId(tempLaboresAdicionalTitular.idenT_UBIGEO);
        return tempDataLaboresAdicionalesTitular;
    }
    ,
    ObtenerDatosLaboresAdicionalesConyuge: (tempLaboresAdicionalConyuge: any): LaboresAdicionalesConyuge => {
        const tempDataLaboresAdicionalesConyuge: LaboresAdicionalesConyuge = LaboresAdicionalesConyugeDefault;
        tempDataLaboresAdicionalesConyuge.ActividadEconomicaId = tempLaboresAdicionalConyuge.iD_ACTIVIDAD_ECONOMICA;
        tempDataLaboresAdicionalesConyuge.PaisId = tempLaboresAdicionalConyuge.idenT_PAIS;
        tempDataLaboresAdicionalesConyuge.NombreVia = tempLaboresAdicionalConyuge.descM_NOMBRE_VIA;
        tempDataLaboresAdicionalesConyuge.NumeroVia = tempLaboresAdicionalConyuge.descC_NUMERO;
        tempDataLaboresAdicionalesConyuge.IntDptoPiso = tempLaboresAdicionalConyuge.descC_INTERIOR_DEPARTAMENTO_PISO;
        tempDataLaboresAdicionalesConyuge.Manzana = tempLaboresAdicionalConyuge.descC_MANZANA;
        tempDataLaboresAdicionalesConyuge.Lote = tempLaboresAdicionalConyuge.descC_LOTE;
        tempDataLaboresAdicionalesConyuge.NombreAgrupacion = tempLaboresAdicionalConyuge.descM_NOMBRE_AGRUPACION;
        tempDataLaboresAdicionalesConyuge.Referencia = tempLaboresAdicionalConyuge.descL_REFERENCIA;
        tempDataLaboresAdicionalesConyuge.Telefono = GeneralHelper.ParseString(tempLaboresAdicionalConyuge.descC_TELEFONO);
        tempDataLaboresAdicionalesConyuge.Anexo = GeneralHelper.ParseString(tempLaboresAdicionalConyuge.descC_ANEXOS);
        tempDataLaboresAdicionalesConyuge.Correo = GeneralHelper.ParseString(tempLaboresAdicionalConyuge.descC_CORREO);
        tempDataLaboresAdicionalesConyuge.DistritoId = tempLaboresAdicionalConyuge.idenT_UBIGEO;
        tempDataLaboresAdicionalesConyuge.ProvinviaId = GeneralHelper.ObtenerProvinciaId(tempLaboresAdicionalConyuge.idenT_UBIGEO);
        tempDataLaboresAdicionalesConyuge.DepartamentoId = GeneralHelper.ObtenerDepartamentoId(tempLaboresAdicionalConyuge.idenT_UBIGEO);
        return tempDataLaboresAdicionalesConyuge;
    },
    ObtenerDetailsStepsOneCard: (tempViewModelStepOneFinal: any): DatosDetailsStepsOneCard => {

        const tempDataDatosDetailsStepsOneCard: DatosDetailsStepsOneCard = DatosDetailsStepsOneCardDefault;

        tempDataDatosDetailsStepsOneCard.EstadoCivilId = tempViewModelStepOneFinal.newTitularData.EstadoCivilId;
        tempDataDatosDetailsStepsOneCard.Titular = tempViewModelStepOneFinal.newTitularData.Nombres + ' ' + tempViewModelStepOneFinal.newTitularData.ApellidoPaterno + ' ' + tempViewModelStepOneFinal.newTitularData.ApellidoMaterno;

        if (tempViewModelStepOneFinal.newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado) {
            tempDataDatosDetailsStepsOneCard.Conyuge = tempViewModelStepOneFinal.newConyugeData.Nombres + ' ' + tempViewModelStepOneFinal.newConyugeData.ApellidoPaterno + ' ' + tempViewModelStepOneFinal.newConyugeData.ApellidoMaterno;
        }

        return tempDataDatosDetailsStepsOneCard;
    },

    ObtenerDetailsStepsOneCardConModellica: (tempDatosDetailsStepsOneCard: DatosDetailsStepsOneCard, tempResponseStepN: any): DatosDetailsStepsOneCard => {
        const tempDataViewModelStepOneFinal: DatosDetailsStepsOneCard = tempDatosDetailsStepsOneCard;
        let objResponse = tempResponseStepN;
        objResponse = objResponse.response;
        if (objResponse.Politica_Creditos.capacidad_Endeudamiento.maximo_Endeudamiento) {

            let cantmaximoEndeudamiento = objResponse.Politica_Creditos.capacidad_Endeudamiento.maximo_Endeudamiento.length;

            let objMaximo_Endeudamiento: any;
            let cfmMinimo = 1;
            objMaximo_Endeudamiento = objResponse.Politica_Creditos.capacidad_Endeudamiento.maximo_Endeudamiento;

            if (cantmaximoEndeudamiento > 0) {
                objMaximo_Endeudamiento.forEach((element: any, index: any) => {
                    let cfmActual = element.CFM;
                    if (cfmActual < cfmMinimo) {
                        cfmMinimo = cfmActual;
                        tempDataViewModelStepOneFinal.MenAPorcentaje = (element.CFM * 100).toFixed(2) + ' %';
                        tempDataViewModelStepOneFinal.MenAValor = appConfigKey.keySimboloSoles + ' ' + GeneralHelper.AgregarComasAMiles(element.MEM.toFixed(2));
                    }

                })
            }
        }

        tempDataViewModelStepOneFinal.CMA = appConfigKey.keySinCMA;
        if (objResponse.Politica_Creditos.motivo_Rechazo) {
            if (objResponse.Politica_Creditos.motivo_Rechazo.Detalle) {
                let detalle = objResponse.Politica_Creditos.motivo_Rechazo.Detalle;
                if (detalle.length > 0) {
                    tempDataViewModelStepOneFinal.CMA = appConfigKey.keyConCMA
                }
            }
        }



        return tempDataViewModelStepOneFinal;
    },

    ObtenerDetailsMenStepsThreeCardConModellica: (tempDatosDetailsStepsOneCard: DatosDetailsStepsOneCard, productoSeleccionado: number, tempResponseStepN: any): DatosDetailsStepsOneCard => {
        const tempDataViewModelStepOneFinal: DatosDetailsStepsOneCard = tempDatosDetailsStepsOneCard;
        let objResponse = tempResponseStepN;
        objResponse = objResponse.response;

        let objMaximo_Endeudamiento: any = [];
        let productos_comerciales: any = [];
        if (objResponse.productos_comerciales) {
            productos_comerciales = objResponse.productos_comerciales;

            if (objResponse.Politica_Creditos.capacidad_Endeudamiento.maximo_Endeudamiento) {

                let cantmaximoEndeudamiento = objResponse.Politica_Creditos.capacidad_Endeudamiento.maximo_Endeudamiento.length;
                objMaximo_Endeudamiento = objResponse.Politica_Creditos.capacidad_Endeudamiento.maximo_Endeudamiento;

                if (cantmaximoEndeudamiento > 0) {
                    productos_comerciales.forEach((producto: any, index: any) => {
                        if (producto.codigo === productoSeleccionado) {
                            let element: any = objMaximo_Endeudamiento.find((x: any) => x.CFM === producto.CFM);
                            if (element) {
                                tempDataViewModelStepOneFinal.MenAPorcentaje = (element.CFM * 100).toFixed(2) + ' %';
                                tempDataViewModelStepOneFinal.MenAValor = appConfigKey.keySimboloSoles + ' ' + GeneralHelper.AgregarComasAMiles(element.MEM.toFixed(2));
                            }
                        }
                    })

                }
            }
        }




        return tempDataViewModelStepOneFinal;
    }


};